<template>
  <div>
    <b-card title="人员信息">
      <div class="custom-search">
        <div>
          <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group id="input-group-1" label="姓名" label-for="input-1" description="请输入姓名">
              <b-form-input id="input-1" v-model="form.userName" type="text" placeholder="姓名" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="身份证号:" label-for="input-2">
              <b-form-input id="input-2" v-model="form.idCard" @input="parseIdCard" placeholder="请输入身份证号"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="出生日期:" label-for="input-3">
              <b-form-input id="input-3" type="date" v-model="form.birthday"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="性别:" label-for="input-4">
              <v-select id="input-4" placeholder="请选择性别" :options="sexOptions" :filterable="false" class="w-100"
                @input="sexHandleChange" v-model="sexInfo">
              </v-select>
            </b-form-group>

            <b-form-group id="input-group-5" label="手机号" label-for="input-5" description="请输入手机号">
              <b-form-input id="input-5" v-model="form.mobile" type="number" placeholder="手机号"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-6" label="备注" label-for="input-6" description="请输入备注">
              <b-form-input id="input-6" v-model="form.remark" type="text" placeholder="备注"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-7" label="紧急联系人" label-for="input-7" description="请输入紧急联系人">
              <b-form-input id="input-7" v-model="form.emergencyContact" type="text" placeholder="紧急联系人"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-8" label="紧急联系人电话" label-for="input-8" description="请输入紧急联系人电话">
              <b-form-input id="input-8" v-model="form.emergencyContactMobile" type="number" placeholder="紧急联系人电话"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-9" label="入住设置:" label-for="input-9">
              <v-select
                  placeholder="请选择入住地点"
                  :options="resourceOptions"
                  :selectable="resource => resource.enable"
                  class="w-100"
                  label="name"
                  v-model="form.resourceInfo"
                  @input="resourceHandleChange"
                  @search="resourceSearch"
              >
                    <li slot="list-footer" class="select-pagination">
                      <button :disabled="!hasResourcePrevPage" @click.stop.prevent="pageResource(false,true)">上一页</button>
                      <button :disabled="!hasResourceNextPage" @click.stop.prevent="pageResource(true,false)">下一页</button>
                    </li>
                </v-select>
            </b-form-group>
            <b-button type="submit" variant="primary">保存</b-button>
            <b-button type="reset" variant="danger" style="margin-left: 10px;">重置</b-button>
          </b-form>
          <b-button style="margin-top: 10px;" class="w-15" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" to="/person/personList">
            返回
          </b-button>
        </div>

      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton,BDropdown,BDropdownItem,BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'
import vSelect from 'vue-select'
import label from '@/navigation/vertical/label'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
          id: null,
          mobile: null,
          idCard: null,
          userName: '',
          sex: 0,
          sexInfo: null,
          birthday: null,
          remark: '',
          emergencyContact: '',
          emergencyContactMobile: null,
          resourceInfo: null,
          resourceId: null
        },
        sexOptions: [
          { label:'男', value: 0 },
          { label: '女', value: 1 },
        ],
      resourceOptions:[],
      resourceSearchCurrentPage: 1,
      resourceSearchPageSize: 5,
      resourceTotal: 0,
      resourceName: null,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
    /**
     * 计算属性
     */
    hasResourceNextPage() {
      return (this.resourceSearchCurrentPage * this.resourceSearchPageSize) < this.resourceTotal
    },
    hasResourcePrevPage() {
      return this.resourceSearchCurrentPage > 1
    },
    sexInfo(){
      return this.sexOptions[this.form.sex]
    }
  },
  created(){
    this.pageResource()
    if(this.$route.query && this.$route.query.id){
      this.getPersonInfo(this.$route.query.id)
    }
  },
  methods: {
    parseIdCard() {
      console.log("parseIdCard")
      let IdCard = this.form.idCard
      if(IdCard && IdCard.length == 18){
            //获取出生日期
            let birthday = IdCard.substring(6, 10) + "-" + IdCard.substring(10, 12) + "-" + IdCard.substring(12, 14)
            this.form.birthday = birthday
            //获取性别
            if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
              this.form.sexInfo = this.sexOptions[0]
            } else {
              this.form.sexInfo = this.sexOptions[1]
            }
        }
    },
    getPersonInfo(id){
      request.get('tob/tenant/tenantPerson/detail', { personId: id}).then(res => {
          if (res.data.success) {
            if (res.data.success) {
              this.form = res.data.data
            }
          }
        })
    },
    resourceHandleChange(val){
      console.log('val',val)
      this.form.resourceInfo = val
      this.form.resourceId = val.id
    },
    sexHandleChange(val){
      this.form.sexInfo = val
      this.form.sex = val.value
    },
    onSubmit(event) {
      event.preventDefault()
      const {id,mobile,idCard,userName,sex,birthday,remark,emergencyContact,emergencyContactMobile,resourceId,status} = this.form
      let requestForm = {id,mobile,idCard,userName,sex,birthday,remark,emergencyContact,emergencyContactMobile,resourceId,status}
      if (requestForm.idCard && requestForm.idCard.length > 18) {
        this.$bvToast.toast("身份证号长度错误",{
          title: `提示`,
          variant: 'warning',
          solid: true
        })
        return
      }
      if (requestForm.remark && requestForm.remark.length > 256) {
        this.$bvToast.toast("备注超过256个字符",{
          title: `提示`,
          variant: 'warning',
          solid: true
        })
        return
      }
      request.post('tob/tenant/tenantPerson/save', requestForm).then(res => {
        if (res.data.success) {
          if (res.data.success) {
            this.$bvToast.toast("操作成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
          }
        }
      })
    },

    onReset(event) {
      event.preventDefault()
      this.form.mobile = null
      this.form.idCard = ''
      this.form.userName = ''
      this.form.sex = 0
      this.form.birthday = null
      this.form.remark = ''
      this.form.emergencyContact = ''
      this.form.emergencyContactMobile = null
      this.form.resourceId = null
      this.form.resourceInfo = null
    },

    pageResource(next,pre){
      if(next)
        this.resourceSearchCurrentPage = this.resourceSearchCurrentPage + 1
      if(pre)
        this.resourceSearchCurrentPage = this.resourceSearchCurrentPage - 1

      //this.resourceTotal = 4
      request.post('tob/tenant/tenantResource/pagePersonResourceBO', {
        currentPage: this.resourceSearchCurrentPage,
        pageSize: this.resourceSearchPageSize,
        name:this.resourceName,
      }).then(res => {
        if (res.data.success) {
          if (res.data.success) {
            this.resourceOptions = res.data.data.data ? res.data.data.data : []
            this.resourceTotal = res.data.data.count
          }
        }
      })
    },

    resourceSearch(query){
      this.resourceName = query;
      this.resourceSearchCurrentPage = 1;
      this.pageResource(false,false)
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
